export const LOCATIONS = {
  // Rostock: {
  //     label: 'Rostock',
  //     id: 'RO',
  //     storageType: ['Box', 'Garage']
  // },
  // 'Coesfeld': {
  //     label: 'Coesfeld',
  //     id: 'CO',
  //     storageType: ['Box', 'Garage']
  // },
  // Schwerin: {
  //     label: 'Schwerin',
  //     id: 'SW',
  //     storageType: ['Box', 'Garage']
  // },
  // 'Augsburg': {
  //     label: 'Augsburg',
  //     id: 'AU',
  //     storageType: ['Box', 'Garage']
  // },
  // 'Fürstenau': {
  //     label: 'Fürstenau',
  //     id: 'FU',
  //     storageType: ['Garage']
  // },
  // 'Ratzeburg': {
  //     label: 'Ratzeburg',
  //     id:'RA',
  //     storageType: ['Garage']
  // }
};

export const LOCATION_ROUTE_PARAM_NAME = ":location";

export const STRIPE_PUBLISHKEY_BY_LOCATIONID_MAP = {
  RO: process.env.VUE_APP_RO_STRIPE_PUBLISHABLE_KEY,
  FU: process.env.VUE_APP_FU_STRIPE_PUBLISHABLE_KEY,
  SW: process.env.VUE_APP_SW_STRIPE_PUBLISHABLE_KEY,
};

export const PRICING_PERIOD_VALUES = {
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
};

export const PRICING_PERIOD_TO_LABEL_MAP_RENT = {
  [PRICING_PERIOD_VALUES.WEEK]: "Wöchentliche",
  [PRICING_PERIOD_VALUES.MONTH]: "Monatliche",
  [PRICING_PERIOD_VALUES.YEAR]: "Jährliche",
};

export const PRICING_PERIOD_TO_LABEL_MAP_TOTAL_PRICE = {
  [PRICING_PERIOD_VALUES.WEEK]: "Wöchentlicher",
  [PRICING_PERIOD_VALUES.MONTH]: "Monatlicher",
  [PRICING_PERIOD_VALUES.YEAR]: "Jährlicher",
};

export const STORAGE_BOOKING_STAGES = {
  UNBEGAN: "UNBEGAN",
  USER_FORM: "USER_FORM",
  CONTRACT: "CONTRACT",
  PAYMENT: "PAYMENT",
  SUMMARY: "SUMMARY",
};

export const STORAGE_BOOKING_STAGES_WITH_LOCATION_PATH = [
  STORAGE_BOOKING_STAGES.USER_FORM,
  STORAGE_BOOKING_STAGES.CONTRACT,
  STORAGE_BOOKING_STAGES.PAYMENT,
  STORAGE_BOOKING_STAGES.SUMMARY,
];

export const STORAGE_BOOKING_STAGES_URL_MAP = {
  [STORAGE_BOOKING_STAGES.UNBEGAN]: "suchkriterien",
  [STORAGE_BOOKING_STAGES.USER_FORM]: "kundendaten",
  [STORAGE_BOOKING_STAGES.CONTRACT]: "vertrag",
  [STORAGE_BOOKING_STAGES.PAYMENT]: "zahlung",
  [STORAGE_BOOKING_STAGES.SUMMARY]: "zusammenfassung",
};

export const BOOKING_TIMER_INITIAL_VALUE_IN_MILLISECONDS = 1200000;

export const HEARD_ABOUT_US_OPTIONS = [
  { label: "Plakatwerbung", value: "plakatwerbung" },
  { label: "Google Suche", value: "google_suche" },
  { label: "Empfehlung von Unternehmen", value: "empfehlung_von_unternehmen" },
  { label: "Empfehlung von Bekannten", value: "empfehlung_von_bekannten" },
  { label: "Lagergebäude gesehen", value: "lagergebaude_gesehen" },
  { label: "Kleinanzeigen", value: "kleinanzeigen" },
  { label: "Facebook", value: "facebook" },
  { label: "Anzeige aus Zeitung/Magazin", value: "anzeige_aus_zeitung_magazin" },
  { label: "Sonstiges", value: "sonstiges" },
];

export const FOOTER_LINKS = [
  { label: "AGB", url: "https://www.lagerwunder.com/terms/agb" },
  { label: "Datenschutzhinweis", url: "https://www.lagerwunder.com/terms/datenschutzhinweise" },
  { label: "Impressum", url: "https://www.lagerwunder.com/terms/impressum" },
];
